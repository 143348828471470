export const SOME_ACTION_REQUEST = 'SOME_ACTION_REQUEST';
export const SOME_ACTION_SUCCEDED = 'SOME_ACTION_SUCCEDED';
export const SOME_ACTION_FAILED = 'SOME_ACTION_FAILED';

export const SET_BRAINSTORMING_SESSION = 'SET_BRAINSTORMING_SESSION';
export const SET_BRAINSTORMING_URL = 'SET_BRAINSTORMING_URL';
export const SET_BRAINSTORMING_STEP = 'SET_BRAINSTORMING_STEP';
export const SET_BRAINSTORMING_IDEAS = 'SET_BRAINSTORMING_IDEAS';
export const CREATE_BRAINSTORMING_IDEA = 'CREATE_BRAINSTORMING_IDEA';
export const UPDATE_BRAINSTORMING_IDEA = 'UPDATE_BRAINSTORMING_IDEA';

export const SET_AUTH_ID = 'SET_AUTH_ID';
